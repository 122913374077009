<template>
    <v-toolbar outlined dense elevation="0">
        <back-arrow-button v-if="typeof previousPageLabel !== 'undefined'" :page-label="previousPageLabel" />
        <v-toolbar-title class="font-weight-medium pr-8">{{ title }}</v-toolbar-title>
        <slot name="contents" />
    </v-toolbar>
</template>
<script>
import BackArrowButton from '@/components/ui/BackArrowButton'
export default {
    components: {
        BackArrowButton
    },
    props: {
        title: { type: String, default: '' },
        previousPageLabel: { type: String, default: undefined },
        buttons: { type: Array, default: () => {
            // { attrs, on }
            return [];
        } },
    },
}
</script>
