var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar",
    { attrs: { outlined: "", dense: "", elevation: "0" } },
    [
      typeof _vm.previousPageLabel !== "undefined"
        ? _c("back-arrow-button", {
            attrs: { "page-label": _vm.previousPageLabel },
          })
        : _vm._e(),
      _c("v-toolbar-title", { staticClass: "font-weight-medium pr-8" }, [
        _vm._v(_vm._s(_vm.title)),
      ]),
      _vm._t("contents"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }